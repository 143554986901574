<template>
   <div class="wrapper full-height">
      <TheHeader v-if="!isHideHeader" />
      <main class="content" ref="main">
         <div class="content-wrapper">
            <slot></slot>
         </div>
      </main>
      <!-- <TheSnackBar ref="snackbar" /> -->
   </div>
</template>

<script>
// import TheSnackBar from '../UI/TheSnackBar.vue';
import TheHeader from './TheHeader.vue';

export default {
   components: { TheHeader },
   props: {
      isHideHeader: {
         type: Boolean,
         default: false,
      },
   },
   // provide() {
   //    return { snackbar: this.callSnackBar };
   // },

   // methods: {
   //    callSnackBar(message) {
   //       this.$refs.snackbar.show(message);
   //    },
   // },
};
</script>

<style lang="sass">
.wrapper
   height: 100%

   &.full-height
      display: flex
      flex-direction: column

   & .content
      flex: 1 0 auto
      margin: 0 auto
      max-width: 1246px
      width: 100%
      padding: 10px 20px

      & .content-wrapper
         height: 100%
         width: 100%
         display: grid
         place-items: center
</style>
