<template>
	<header class="header">
		<router-link :to="{ name: 'main' }">
			<img src="../../assets/img/logo.svg" />
		</router-link>

		<SWButton :variant="'secondary'" :href="loginUrl"> Login </SWButton>
	</header>
</template>

<script>
import { SWButton } from 'sw-ui-kit';
export default {
	components: { SWButton },
   data() {
      return {
         loginUrl: process.env.VUE_APP_FRONTEND_URL
      };
   },
};
</script>

<style lang="sass">
.header
   display: flex
   align-items: center
   justify-content: space-between

   max-width: 1360px
   width: 100%
   padding: 10px 20px
   margin: 0 auto
   margin-bottom: 20px
</style>
