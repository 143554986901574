import { render, staticRenderFns } from "./TheQuizSec.vue?vue&type=template&id=75c67dd5&scoped=true&"
import script from "./TheQuizSec.vue?vue&type=script&lang=js&"
export * from "./TheQuizSec.vue?vue&type=script&lang=js&"
import style0 from "./TheQuizSec.vue?vue&type=style&index=0&id=75c67dd5&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75c67dd5",
  null
  
)

export default component.exports