<template>
	<LayoutBase :isHideHeader="true">
		<div class="block-cover">
			<div class="page-header-quiz">
				<SWNavButton :size="'small'" @click="prevPage" v-if="isHasPrevPage">Back</SWNavButton>

				<p>Step {{ page + 1 }} of {{ questions.length }}</p>
			</div>
			<div>
				<div class="question" :key="question.id" v-for="(question, index) in questions">
					<!-- <transition name="fade"> -->
					<div class="content-box-thehome-second" v-if="page === index">
						<div class="the-home-second-img"><img :src="question.img" alt="" /></div>
						<div class="content-the-home">
							<p class="the-home-second-paragraf-quiz" :key="text" v-for="text in question.textContent">
								{{ text }}
							</p>

							<SWButton
								class="quiz-button"
								@click="makeAnswer(question.id, 'Yes')"
								variant="secondary"
								size="big"
								fullWidth
								>Yes</SWButton
							>
							<SWButton
								class="quiz-button"
								@click="makeAnswer(question.id, 'No')"
								variant="secondary"
								size="big"
								fullWidth
								>No</SWButton
							>
						</div>
					</div>
					<!-- </transition> -->
				</div>
			</div>
		</div>
	</LayoutBase>
</template>

<script>
import LayoutBase from '../components/layouts/LayoutBase.vue';
import { SWNavButton } from 'sw-ui-kit';
import { SWButton } from 'sw-ui-kit';

const QUESTION = [
	{
		id: 1,
		img: require('../assets/img/ImageQstep1.png'),
		textContent: [
			'Do you fall asleep quickly (within 15 min) and sleep through the night?',
			'Do you wake up easily and  are ready to start your day in 15 min?',
		],
	},
	{
		id: 2,
		img: require('../assets/img/ImageQstep2.png'),
		textContent: ['Do you feel focused and productive throughout the day?'],
	},
	{
		id: 3,
		img: require('../assets/img/ImageQstep3.png'),
		textContent: [
			'Do you notice a decline in your productivity and concentration during the day?',
			'Do you feel irritated and sleepy?',
		],
	},
	{
		id: 4,
		img: require('../assets/img/ImageQstep4.png'),
		textContent: [
			'Did you know that',

			'- we need up to 4 days to recover from 1 hour of lost sleep?',

			'- 21% of car accidents occur when the driver is drowsy?',

			'- lack of sleep reflects negatively on your immune system, increase anxiety and reduce pain tolerance?',
		],
	},
	{
		id: 5,
		img: require('../assets/img/ImageQstep5.png'),
		textContent: [
			'Do you want to become more energetic, productive, and get more done in one day? To improve your memory,attention span, self-control, concentration, and clarity? You can achieve all that by just improving your sleep!',
		],
	},
];
export default {
	components: {
		SWButton,
		LayoutBase,
		SWNavButton,
	},
	data() {
		return {
			page: 0,
			answers: {},
		};
	},

	computed: {
		questions() {
			return QUESTION;
		},
		isHasPrevPage() {
			return this.page !== 0;
		},
	},
	methods: {
		makeAnswer(id, answer) {
			this.answers[id] = answer;
			this.nextPage();
		},
		nextPage() {
			if (this.questions.length !== this.page + 1) {
				this.page += 1;
			} else if (this.questions.length === this.page + 1) {
				this.$router.push({ name: 'results', params: { answers: this.answers } });
			}
		},
		prevPage() {
			this.page -= 1;
		},
	},
};
</script>

<style lang="sass" scoped>
.question
   width: 100%
.quiz-button
   margin-bottom: 15px
   @media screen and (max-width:600px)
      margin-bottom: 0px
      margin-top: 15px
   @media screen and (max-width:380px)
      margin-bottom: 0px
      margin-top: 10px

.content-box-thehome-second
   display: flex
   width: 100%
   justify-content: center
   align-items: center

   @media screen and (max-width: 1100px)
      justify-content: center
      align-items: center
   @media screen and (max-width: 880px)
      flex-direction: column
.page-header-quiz
   display: flex
   align-items: center
   justify-content: center
   width: 100%
   place-self: baseline
   margin-top: 0px
   margin-bottom: 15%
   @media screen and (max-width: 880px)
      margin: 15px 0px 23px 0px

.page-header-quiz p
   white-space: nowrap
   margin: 0 auto
   transform: translateX(-50%)

@media screen and (max-width: 650px)
   .page-header-quiz p:nth-child(1)
      transform: translateX(-8%)

@media screen and (max-width: 380px)
   .the-home-second-paragraf-quiz:nth-child(4)
      margin-bottom: 0px
.btn-header-page
   display: block
.the-home-second-paragraf-quiz
   font-size: 24px
   font-weight: 400
   margin-bottom: 25px
   @media screen and (max-width:900px)
      font-size: 22px
      line-height: 22.5px
   @media screen and (max-width:375px)
      font-size: 22px
      margin-bottom: 15px

.wrapper .content .content-wrapper
   display: block
.page-header-quiz
   font-size: 12px
   font-weight: 400
.content-the-home
   max-width: 439px
   width: 100%
   margin-right: 100px
   @media screen and (max-width: 1100px)
      margin-right: 0px
      margin-top: 50px
   @media screen and (max-width: 480px)
      margin-top: 0px

.the-home-second-title
   font-size: 32px
   font-weight: 700
   margin-bottom: 32px
   @media screen and (max-width: 480px)
      margin-bottom: 20px

.the-home-second-recomeid
   font-size: 12px
   font-weight: 400
   margin-top: 64px
   margin-bottom: 60px
   @media screen and (max-width: 480px)
      font-size: 10px
      margin-top: 18px
      margin-bottom: 55px

.block-cover
   width: 100%
   @media screen and (max-width:880px)
      height: 100%
.the-home-second-img
   max-width: 730px
   width: 100%
   margin-right: 30px

   @media screen and (max-width: 1100px)
      margin: 0 auto
      margin-right: 30px
   @media screen and (max-width: 880px)
      // margin-right: 0
      max-width: 500px
   @media screen and (max-width: 480px)
      margin-bottom: 50px
      margin-top: 25px
   @media screen and (max-width:380px)
      max-width: 280px
      margin-bottom: 20px
      margin-top: 0
</style>
